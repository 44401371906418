<template>
  <div class="wrap">
    <div class="filter">
      <el-input
        style="width: 250px"
        placeholder="请输入手机号筛选"
        v-model="search_str"
        class="input-with-select"
      >
        <el-button
          @click="search"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </el-input>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        v-for="(item, index) in tableData_cell"
        :key="index"
        :prop="item.value"
        :label="item.title"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="redact(scope.row.mobile)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        :page-size="20"
        :current-page="pageNow"
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { ts_radar_mobile, ts_radar_mobile_delete } from "@/api/tool";
import { showCity } from "@/api/resume";
import axios from "axios";
export default {
  data() {
    return {
      //总页码
      pageTotal: null,
      pageNow: 1,

      search_str: "",

      // 表单数据处理
      tableData_cell: [
        { title: "电话", value: "mobile" },
        { title: "添加时间", value: "create_time" },
      ],
      tableData: [],
      // 编辑
      indry: [],
      city: [],
      is_seed: false,
      dialogFormVisible: false,
      formamend: {
        username: "",
        contact_name: "",
        license_company_name: [],
      },
      place_data: [],
      industry_name: "",
      user_detail_id: "",
      form: {},
      activeName: "essentialInformation",
    };
  },
  mounted() {},
  methods: {
    pageNowHandler(page) {
      console.log("pageNowHandler", page);

      if (this.pageNow != page) {
        this.loading = true;

        this.pageNow = page;

        this.search();
      }
    },

    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    search() {
      console.log(this.search_str, "123345");
      let search_data = {
        mobile: "",
        page: this.pageNow,
      };

      if (this.search_str) {
        search_data = {
          mobile: this.search_str,
          page: this.pageNow,
        };
      }
      ts_radar_mobile({ mobile: this.search_str, page: this.pageNow }).then(
        (res) => {
          if (!res.code) {
            if (res.data.length) {
            }
            this.pageTotal = res.all_count;
            this.tableData = res.data;
            // this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg);
            console.log("ssssssssss");
          }
          console.log(res);
        }
      );
    },

    redact(mobile) {
      //删除
      this.$confirm("是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          ts_radar_mobile_delete({ mobile: mobile }).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message.success("删除成功!");
            }

            this.search();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },

  created() {
    this.pageNow = 1;
    this.search();
  },
};
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 30%;
  margin-right: 10px;
}

.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
</style>
